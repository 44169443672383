* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, sans-serif;
  overflow-wrap: break-word;
  overflow-x: hidden;
}
html,
body {
  overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
}

li {
  margin-left: 20px;
  list-style-position: inside;
}



/*##################
NAVBAR
#################
*/
.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.6%;
  margin: 0;
  background: #f4f4ed;
  position: fixed;
  width: 100%;
  z-index: 999;
  overflow: hidden;
  top: 0;
}

.nav-toggle-buttons{
  margin: 20px;
  text-decoration: none;
  color: #60afff;
}

.navbar .menu-items {
  display: flex;
}

.navbar .menu-items a, .navbar .menu-items .servicos-menu {
  padding: 0 20px 0 20px;
  color: #60afff;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  cursor:pointer
}

.navbar .menu-items a:hover,
.navbar .menu-items a.active, .navbar .menu-items .servicos-menu:hover, .navbar .menu-items .servicos-menu:active {
  color: #525252;
}
.navbar + .content {
  padding-top: 60px;
}

.navbar .menu-toggle{
  display: none;
}

@media only screen and (max-width: 1023px) {
  .navbar .menu-items{
    display: none;
  }
  .navbar{
    padding: 20px 20px;
    justify-content: space-between;
    height: 50px;
  }

  .navbar .menu-toggle{
    display: block;
    margin-right: 50px;
  }

}
/*##################
HERO
#################
*/

.content .hero {
  position: relative;
  background-size: cover;
}

.content .overlay {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.76) 50%,
    rgba(0, 0, 0, 0.5) 50%
  );
  height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: start;
}

.content .overlay h2 {
  padding: 50px;
  color: rgb(255, 255, 255);
  width: 40%;
  margin: 0;
  line-height: 2;
}

@media only screen and (max-width: 1023px) {
  .content .overlay {
    background: rgba(0, 0, 0, 0.767);
  }

  .content .overlay h2 {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {

  .content .overlay h2 {
    width: 100%;
    font-size: 20px;
  }

}

/*##################
QUEM SOMOS
#################
*/


.content .quem-somos {
  background: white;
  display: flex;
  justify-content: center;
  text-align: start;
}

.content .quem-somos .quem-somos-wrapper {
  display: flex;
}

.content .quem-somos .bio {
  inline-size: 600px;
  margin-top: 2%;
  font-size: 16px;
  color: rgb(129, 129, 129);
  font-weight: 400;
  line-height: 25px;
  padding: 0 20px;
}

.content .quem-somos .bio h3 {
  margin: 30px 0;
  color: rgb(61, 61, 61);
  overflow-y: hidden;
}

.content .quem-somos .bio .contact-button {
  height: 50px;
  width: 180px;
  background: #440381;
  color: rgb(233, 233, 233);
  font-size: 16px;
  margin-top: 20px;
  border-radius: 3px;
  cursor: pointer;
}

.content .quem-somos .cards {
  width: 100%;
  padding: 20px 0 0 0;
}

.content .quem-somos .cards .icon-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.content .quem-somos .cards .card-container {
  background: white;
  margin: 25% 0%;
  margin-bottom: 0;
  width: 200px;
  -webkit-box-shadow: 0px 8px 50px -8px rgba(145, 145, 145, 0.49);
  box-shadow: 0px 8px 50px -8px rgba(145, 145, 145, 0.49);
  height: 250px;
}

.content .quem-somos .cards .card-container .avatar {
  display: flex;
  justify-content: center;
  height: 90px;
  padding-top: 20px;
}

.content .quem-somos .cards .card-container .avatar img {
  border-radius: 50px;
}

.content .quem-somos .cards .card-container .card-bio {
  text-align: center;
  margin-top: 20px;
  line-height: 25px;
}

.content .statistics .statistics-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content .statistics .card-container {
  background: white;
  -webkit-box-shadow: 0px 8px 50px -8px rgba(145, 145, 145, 0.49);
  box-shadow: 0px 8px 50px -8px rgba(145, 145, 145, 0.49);
  height: 200px;
  width: 300px;
}

.content .statistics .card-container + .card-container {
  margin-left: 30px;
}

.content .statistics .card-container .avatar {
  display: flex;
  justify-content: center;
  height: 90px;
  padding-top: 20px;
}

.content .quem-somos .cards .card-container .card-bio {
  text-align: center;
  margin-top: 20px;
  line-height: 25px;
}

.content .statistics {
  margin: 100px 0;
  background: #440381;
  padding: 20px 10px;
  text-align: center;
}

.content .statistics h3 {
  color: white;
  padding-bottom: 40px;
  padding-top: 20px;
}

.content .statistics .statistics-wrapper {
  display: flex;
  flex-direction: column;
}

.content .statistics .stats-container {
  background: white;
  color: rgb(129, 129, 129);
  padding: 30px;
  width: 200px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 18px 50px -8px rgba(17, 17, 17, 0.49);
  box-shadow: 0px 18px 50px -8px rgba(20, 20, 20, 0.49);
}

.content .statistics .stats-container h1 {
  font-size: 50px;
  color: rgb(78, 78, 78);
}

.content .statistics .stats-container + .stats-container {
  margin-left: 20px;
}

@media only screen and (max-width: 1023px) {
  .content .quem-somos {
    width: 100%;
  }

  .content .quem-somos-wrapper {
    padding: 0; 
  }

  .content .quem-somos .bio{
    margin-bottom: 80px;
    font-size: 18px;
    line-height: 30px;
  }

  .content .quem-somos .bio h3{
    overflow-y: hidden;
    height: auto;
  }

  .content .quem-somos-wrapper {
    flex-direction: column;
    padding: 0px 0px;
  }


  .content .statistics h3 {
      font-size: 30px;
  }

  .content .statistics .statistics-cards {
    width: 100%;
    text-align: center;
    overflow: hidden;
    padding-left: 0px;
  }

  .content .statistics .statistics-cards {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .content .statistics .statistics-cards::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }


  .content .statistics .statistics-cards > .stats-container {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 300px;
    height: 300px;
    flex-direction: column;
    margin-right: 50px;
    left: 80%;
    border-radius: 5px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

}
.content .quem-somos-wrapper .cards > div >div >div{
  margin-bottom:1px;
}

@media only screen and (max-width: 670px){
  .content .statistics .statistics-cards > .stats-container {
      left: 700px;
  }
}

@media only screen and (max-width: 515px) {
  .content .statistics h3 {
      font-size: 30px;
  }

  .content .statistics .statistics-cards {
    width: 100%;
    text-align: center;
    overflow: hidden;
    padding-left: 40px;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;

  }

  .content .statistics .statistics-cards::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }


  .content .statistics .statistics-cards > .stats-container {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 200px;
    height: 200px;
    left: 500px;
    flex-direction: column;
    margin-right: 20px;
    border-radius: 5px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

}

@media only screen and (max-width: 1023px) and (min-width: 616px) {


}

@media only screen and (max-width: 615px) {
  .content .quem-somos-wrapper .bio p{
    padding: 0 0px;
  }

  .content .quem-somos-wrapper .contact-button{
    margin-left: 200px;
  }
  
  .content .quem-somos-wrapper .bio h3{
    text-align: center;
  }

  .content .quem-somos-wrapper .bio{
    width: 100%;
    padding: 0;
  }

}

@media only screen and (max-width: 599px) {
  .content .quem-somos-wrapper .cards > div >div{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .content .quem-somos-wrapper .cards > div >div >div{
    width: 300px;
    margin: 0px 0px 2px 0;
  }

  .content .quem-somos-wrapper .cards > div >div >div img{
    height: 300px;
  }

  .content .quem-somos-wrapper .cards {
    background: linear-gradient(180deg, #440381, 50%, #fff 50%);
    padding: 100px 0;
  }
  .content .quem-somos-wrapper .bio p{
    padding: 0 20px;
  }
}

  @media only screen and (max-width: 515px) {

    .content .quem-somos-wrapper .bio{
      width: 100%;
    }
  .content .quem-somos-wrapper .bio p{
    padding: 0 20px;
  }

  .content .quem-somos-wrapper .contact-button{
    margin-left: 200px;
  }
  
  .content .quem-somos-wrapper .bio h3{
    text-align: center;
  }
  

  
}
@media only screen and (min-width: 1024px) {
.content .quem-somos .quem-somos-wrapper {
  padding: 0px 50px;
} 
}

@media only screen and (min-width: 600px) {
.content .quem-somos-wrapper .cards{
  background: linear-gradient(180deg, #440381, 20%, #fff 20%);
}
}


/*##################
Serviços
#################
*/

.content .servicos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 100px 0;
  width: 100%;
}

.content .servicos .servicos-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  padding: 0 40px;
}
.content .servicos img {
  width: 500px;
  height: 700px;
}

.content .servicos .servicos-description {
  height: 100%;
  width: 100%;
  margin-left: 40px;
  margin-top: 20px;
  inline-size: 600px;
}

@media only screen and (min-width: 1024px){
  .content .servicos .servicos-description{
    width: 50%;
  }
}

.content .servicos .servicos-description p {
  color: #440381;
  font-weight: 700;
  margin-bottom: 10px;
}

.content .servicos .servicos-description h2 {
  font-size: 28px;
  margin-bottom: 30px;
  inline-size: 400px;
}

.content .servicos .servicos-description .tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.content .servicos .servicos-description .tabs button {
  width: 49%;
  margin-right: 2px;
  height: 40px;
  background: white;
  border-radius: 3px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  border: 1.5px solid #d4d4d4;
}
.content .servicos .servicos-description .tabs button:hover,
.content .servicos .servicos-description .tabs button.active {
  background: #440381;
  color: white;
  transition: 0.5s;
  cursor: pointer;
}

.content .servicos .servicos-description .tab-field {
  background: #f4f4ed;
  color: rgb(100, 100, 100);
  padding: 20px;
  line-height: 25px;
  font-size: 16px;
  border-radius: 5px;
}

@media only screen and (max-width: 1023px) {
  .content .servicos img {
    width: 0;
  }

  .content .servicos .servicos-description {
    margin: 0;
    inline-size: 600px;
  }

  .content .servicos{
    margin: 10px 0;
  }

  .content .servicos .servicos-description .tab-field {
      font-size: 18px;
      line-height: 30px;
  }

}

@media only screen and (max-width: 614px) {
  .content .servicos-wrapper{
    padding: 0 20px;
  }
}

/*##################
Contato
#################
*/

.content .contato {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.content .contato img {
  width: 50%;
}

.content .contato .form-wrapper {
  background: #440381;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content .contato .form-wrapper p {
  color: #905ebe;
  font-weight: 700;
  padding-top: 20px;
  padding-left: 50px;
  font-size: 18px;
}
.content .contato .form-wrapper h1 {
  color: white;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-top: 10px;
}

.content .contato .form-wrapper form {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  width: 50%;
}

.content .contato .form-wrapper form input {
  background: #440381;
  border: 1px solid #60afff;
  margin-top: 15px;
  height: 40px;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.content .contato .form-wrapper form textarea {
  background: #440381;
  border: 1px solid #60afff;
  margin: 15px 0;
  height: 150px;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.content .contato .form-wrapper form input:focus,
.content .contato .form-wrapper form textarea:focus {
  background: #440381;
  border: 1px solid #60afff;
}
.content .contato .form-wrapper form input::placeholder,
.content .contato .form-wrapper form textarea::placeholder {
  color: white;
}
.content .contato .form-wrapper form button {
  background: #60afff;
  border: 0px solid #60afff;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  width: 50%;
  border-radius: 5px;
}

.content .contato .form-wrapper form button:hover {
  background: #1c0135;
  cursor: pointer;
  transition: 0.3s;
}

@media only screen and (max-width: 1023px) {
  .content .contato .form-wrapper form {
    width: 85%
  }

  .content .contato img {
    width: 0%;
  }
}
@media only screen and (max-width: 515px) {
  .content .contato .form-wrapper form{
    width: 80%
  }
}

/*##################
Blog
#################
*/

.content .blog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f1eff369;
}

.content .blog p {
  margin-top: 80px;
  font-weight: 700;
  color: #440381;
}

.content .blog h1 {
  margin-top: 15px;
  color: rgb(68, 68, 68);
}

.content .blog .blog-posts {
  display: flex;
  margin-top: 70px;
}

.content .blog .blog-container {
  width: 300px;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.content .blog .blog-container + .blog-container {
  margin-left: 20px;
}

.content .blog .blog-container h4 {
  margin-bottom: 20px;
  text-align: start;
  width: 100%;
  color: rgb(65, 65, 65);
}

.content .blog .blog-container img {
  width: 100%;
  height: 250px;
}

.content .blog .blog-container .blog-bio p {
  padding: 0;
  margin-top: 20px;
  inline-size: 100%;
  color: rgb(95, 95, 95);
  font-weight: 400;
}

.content .blog .blog-container button {
  margin-top: 20px;
  padding: 5px;
  width: 90px;
  background: #440381;
  color: white;
}

.content .blog .ver-todos {
  margin: 80px;
  background: #440381;
  color: white;
  width: 150px;
  padding: 10px;
}

.content .blog .ver-todos:hover,
.content .blog .blog-container button:hover {
  cursor: pointer;
  background: #360266;
  transition: 0.3s;
}

@media only screen and (max-width: 1023px) {
  .content .blog .blog-posts {
    width: 100%;
    text-align: center;
    overflow: hidden;
    padding-left: 40px;
  }

  .content .blog .blog-posts {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .content .blog .blog-posts::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }


  .content .blog .blog-posts > .blog-container {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 400px;
    margin-right: 50px;
    border-radius: 5px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

  
  html,
  body {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 515px) {
  .content .blog{
    padding-left: 40px;
  }

  .content .blog h1, .content .blog > p{
    width: 100%;
    text-align: center;
    margin-left: -40px;
  }
  .content .blog > a{
    margin-left: -40px;
  }
  .content .blog .blog-posts {
    width: 100%;
    text-align: center;
    overflow: hidden;
    padding-left: 40px;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  .content .blog .blog-posts::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }


  .content .blog .blog-posts > .blog-container {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 350px;
    margin-right: 50px;
    border-radius: 5px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
}

/*##################
Footer
#################
*/

.content .footer {
  background: #3e0275;
  color: white;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
  height: 200px;
  padding-top: 40px;
}

.content .footer .logo img {
  width: 200px;
}

.content .footer .contato,
.content .footer .horario {
  display: flex;
  flex-direction: column;
}
.content .footer .contato h2,
.content .footer .horario h2 {
  margin-bottom: 20px;
}

.content .footer .contato p,
.content .footer .horario p,
.content .footer .contato a {
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {

  .content .footer {
    background: #3e0275;
    color: white;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    height: 200px;
    padding-top: 40px;
  }
  
  .content .footer .logo img {
    width: 100px;
  }
  
  .content .footer .contato,
  .content .footer .horario {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  .content .footer .contato h2,
  .content .footer .horario h2 {
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .content .footer .contato p,
  .content .footer .horario p,
  .content .footer .contato a {
    margin-bottom: 10px;
    font-size: 14px;
  }

}

/*##################
Blog post
#################
*/

.page-blog {
  background: #f1eff369;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px ;
}
.content-blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 40px;
  padding-top: 0;
}
.content-blog .thumb {
  display: flex;
  justify-content: center;
}

.content-blog .thumb img {
  position: relative;
  width: 100%;
  height: 500px;
}

.content-blog .blog-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  inline-size: 900px;
  word-wrap: break-word;
  line-height: 30px;
}

.page-blog .return {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
}
.page-blog .return img {
  width: 30px;
}

.page-blog .return img:hover {
  cursor: pointer;
}

.content-blog .blog-content h1 {
  color: rgb(66, 66, 66);
  overflow-y: hidden;
}

.content-blog .blog-content p {
  color: rgb(66, 66, 66);
  margin-top: 10px;
}

.content-blog .blog-content article {
  margin-top: 40px;
  color: rgb(88, 88, 88);
}

.content-blog .blog-content .publisher .publisher-avatar {
  display: flex;
  width: 100px;
  padding-top: 20px;
}

.content-blog .blog-content .publisher .publisher-avatar img {
  width: 100%;
  border-radius: 50px;
}

.content-blog .blog-content .publisher .publisher-bio {
  margin-top: 10px;
  line-height: 10px;
  color: rgb(66, 66, 66);
}

.content-blog .blog-content .publisher .publisher-bio p {
  font-size: 14px;
  padding: 20px 0;
  font-weight: 700;
  width:100%;
  justify-content: flex-start;
}

@media only screen and (max-width: 600px) {

  .page-blog{
    padding: 0;
  }
  .content-blog{
    padding:20px;
  } 

  .content-blog .blog-content{
    width: 100%;
  }



}

/*##################
Post List
#################
*/

.blog-posts-grid {
  padding: 50px;
  justify-self: center;
}

.blog-posts .ver-mais-wrapper {
  display: flex;
  justify-content: center;
}
.blog-posts .ver-mais {
  margin: 80px;
  background: #440381;
  color: white;
  width: 150px;
  padding: 10px;
  justify-self: center;
  transition: 0.5s;
}

.blog-posts .ver-mais:hover,
.blog-posts .blog-container button:hover {
  cursor: pointer;
  background: #360266;
  transition: 0.3s;
}

.blog-posts .return {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  justify-content: flex-start;
  margin-left: 50px;
  margin-top: 30px;
}
.blog-posts .return img {
  width: 30px;
}

.blog-posts .return img:hover {
  cursor: pointer;
}

.blog-posts >div > div >div{
  margin-bottom: 2px;
}


.servicos-menu{
  margin:0;
  padding: 0
}
.dropdown {
  margin-left:-20px;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 9999999;
  list-style-type: none
}
 .dropdown a {
  margin-top: 10px;
  color: black;
  height: 30px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.quem-somos .MuiCardContent-root {
  height: 120px !important;
}

.quem-somos .MuiGrid-item{
  width: 200px;
}

@media only screen and (min-width: 1818px){
  .quem-somos .MuiGrid-item{
    width: 150px;
  }
  .quem-somos .MuiCardContent-root {
    height: 150px !important;
  }
}
